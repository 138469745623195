.navbar {
  justify-content: center;
  background-color: #e74c3c !important; /* Red highlight color */
  color: white !important; /* White text */
  padding-top: 1rem !important; /* Adjust padding as needed */
  padding-bottom: 1rem !important;
  font-size: 1.5rem !important; /* Double the font size */
  line-height: 2rem !important; /* Adjust line-height for better spacing */
}

.navbar a {
  color: white !important;
}

.navbar span {
  color: white !important;
}

.navbar-nav {
  flex-direction: row;
  gap: 1rem;
}

.navbar-nav li {
  list-style: none;
  display: inline-block; /* Force horizontal arrangement */
}

.nav-item {
  text-align: center;
  margin-right: 0;
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* Light grey background */
  color: #333; /* Dark grey text */
  text-align: center; /* Center align text content within the body */
}

.container {
  max-width: 800px;
  margin: 20px auto; /* Center the container horizontally */
  background-color: #fff; /* White container background */
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: left; /* Reset text alignment within the container */
}

h1 {
  text-align: center;
  color: #2980b9; /* Blue heading */
  margin-bottom: 20px;
}

h2 {
  color: #34495e; /* Darker grey subheadings */
  margin-top: 30px;
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  color: #e74c3c; /* Red highlight */
}

ul {
  list-style: disc; /* Remove default bullets */
  padding: 0;
  margin-left: 20px;
}

/* Style for nested lists */
ul ul {
  list-style: circle; /* Add dots to nested list items */
  margin-left: 30px; /* Indent nested lists further */
  margin-top: 8px; /* Add space above the nested list */
}

li {
  margin-bottom: 8px;
}

a {
  color: #2980b9; /* Blue link color */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 20px; /* Center the image */
}

/* Tab Container */
.product-tabs {
  display: flex;
  justify-content: flex-start; /* Align tabs to the left */
  margin-bottom: 20px;
}

/* Individual Tabs */
.product-tab {
  padding: 10px 15px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #fff;
  margin-right: 0; /* Remove spacing between tabs */
}

.product-tab.active {
  background-color: #f3f3f3; /* White background for the active tab */
}

.product-details {
  background-color: #f3f3f3; /* Light grey background for product details */
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.selection {
  display: flex; 
  flex-direction: column; 
}

.selection img {
  height: auto;
  display: block; 
  margin-top: 0.5rem; 
  margin-left: 0; /* Explicitly set left margin to 0 */
  margin-bottom: 0;
}

.figure-color-selection img {
  width: 100px;
}

.rod-type-selection img {
  width: 500px; 
}

.handle-type-selection img {
  width: 200px;
}

.figure-selection img {
  height: 150px;
}

.timer-color-selection img {
  width: 100px;
}

.shipping-cost {
  font-size: 0.8rem; /* Adjust the font size as needed */
}

.order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.status {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: lightgrey;
  display: inline-block;
}

.status.success {
  color: green;
  background-color: lightgreen;
}

.status.error {
  color: red;
  background-color: lightpink;
}

.impressum {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 20px;
}

.impressum a {
  color: black;
}

table {
  border-collapse: collapse; /* Merges borders for a cleaner look */
  width: 100%; /* Ensures the table takes up full width of its container */
  max-width: 800px; /* Optional: Limits table width for better readability on larger screens */
  margin: 20px auto; /* Centers the table horizontally */
}

th, td {
  text-align: left;
  padding: 12px 15px;
  border: 1px solid #ddd; /* Adds a subtle border to each cell */
}

th {
  background-color: #f2f2f2; /* Light gray background for header cells */
  font-weight: bold;
}

td {
  background-color: #f9f9f9; /* Alternating row colors for better visual separation */
}

.warranty {
  color: darkred;
  margin-bottom: 20px;
}